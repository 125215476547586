import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import { useRouter } from 'next/router';
import ModalBg from '@/assets/images/vector/modal-bg.svg';

const NotFoundPage = () => {
  const { push } = useRouter();

  return (
    <div className={'flex w-full h-screen bg-black-100 flex items-center justify-center'}>
      <div className="relative rounded-[32px] w-[380px] p-0">
        <div className="relative bg-black-200 rounded-[inherit] p-8 z-10 overflow-hidden max-h-[300px] flex justify-center">
          <ModalBg className="absolute z-0 left-0 top-0 w-full" />
          <div className="relative z-10 w-full max-w-[148px]">
            <Text variant="h1" className="mb-6 text-white text-center">
              Oops 😥
            </Text>
            <Text className="mb-4 text-center" variant="body-1" textColor="white">
              Something went wrong. Please try again
            </Text>
            <Button wrapperClass="bg-pink" onClick={() => push('/')}>
              <Text textColor="white" variant="btn-1">
                Back
              </Text>
            </Button>
          </div>
        </div>
        <div className={'absolute left-[3px] top-[3px] h-full w-full bg-pink rounded-[34px] z-0'} />
      </div>
    </div>
  );
};

export default NotFoundPage;
